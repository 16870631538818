@import "./_common/assets/variables"

@font-face
    font-family: "Aldrich"
    src: url("./_common/assets/fonts/Aldrich-Regular.ttf")

@font-face
    font-family: "Karla"
    src: url("./_common/assets/fonts/Karla-Regular.ttf")

html
    body
        background-color: $main-background
        font-family: "Aldrich"

        ::-webkit-scrollbar 
            width: 10px

        ::-webkit-scrollbar-track
            box-shadow: inset 0 0 5px $disabled-grey 
        
        ::-webkit-scrollbar-thumb
            background: $disabled-grey

        ::-webkit-scrollbar-thumb:hover
            background: $text-color

        .app-container
            height: 91vh
            display: flex
            width: 100%
            align-items: center
            justify-content: center
            padding: 0

        .button.btn
            background-color: $main-green
            color: $button-text
            border-radius: 0
            padding: 10px 25px
            &.button:hover
                background-color: $button-hover
                color: $button-text
        
        form
            .form-control
                &:focus
                    background-color: transparent
                    color: $light-grey

        select
            background-image: url("./_common/assets/images/select-arrow.svg")
            background-repeat: no-repeat
            background-position-x: 99%
            background-position-y: 12px
            background-size: 15px

        .Toastify__toast
            .Toastify__toast-body
                font-family: "Aldrich"
                align-items: flex-start
                padding-bottom: 30px
            .Toastify__close-button
                position: absolute
                left: 0
                bottom: 0
                width: 100%
                svg
                    display: none
                &::after
                    content: "Close"
                    font-family: "Aldrich"
                
            
        .Toastify__toast--error
            border: 1px solid $error-color
            &.Toastify__toast-theme--dark
                color: $error-color
            .Toastify__close-button
                background-color: $error-color
        
        .Toastify__toast--success
            border: 1px solid $main-green
            &.Toastify__toast-theme--dark
                color: $main-green
            .Toastify__close-button
                background-color: $main-green
        
        .btn-check:checked + .btn,
        :not(.btn-check) + .btn:active,
        .btn:first-child:active,
        .btn.active,
        .btn.show,
        .btn:focus,
        .btn:active
            border: none
            outline: none
            box-shadow: none