@import "src/_common/assets/variables"

.form-check
    input[type="checkbox"],
    .form-check-input[type=checkbox]
        border-color: $text-color
        background-color: transparent
    
    .form-check-input:focus
        box-shadow: none
    
    .form-check-label
        color: $text-color