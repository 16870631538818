$main-background: #222629
$secondary-background: #2C2D30
$text-color: #969696
$main-green: #A5C100
$button-hover: #BCEA13
$button-text: #222629
$button-disabled: #474A4F
$menu-background: #474A4F
$side-menu-background: #60646B
$light-grey: #E6E7E8
$disabled-grey: #494C50
$card-background: #474A4F
$dropdown-background: #333538
$component-disabled: #35373B
$error-color: #FF4500
$component-invalid: #FFDF00
$warning-color: #FF9900
$sidebar-background: #353B40
$pending-package: #D28AE6