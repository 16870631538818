@import "src/_common/assets/variables"

nav.navbar
    background-color: $menu-background
    min-height: 9vh
    z-index: 2

    .container-fluid
        margin: 0 80px
        display: flex
        align-items: flex-end
        .menu-items
            display: flex
            align-items: flex-end
            gap: 2em
            .nav-link
                color: $light-grey
                fill: $light-grey
                &:hover
                    color: $button-hover
                    fill: $button-hover

            .ibl
                color: $main-green
                fill: $main-green

            .active
                color: $main-green
                fill: $main-green
                &:hover
                    color: $button-hover
                    fill: $button-hover

        .dropdown-container
            position: relative
            z-index: 1
            .dropdown-list
                position: absolute
                background-color: $dropdown-background
                color: $main-green
                padding: 5px 15px
                right: 0
                width: 100%
                min-width: 250px
                text-align: right
                .account
                    padding: 8px
                .disconnect-btn
                    width: 100%
                    text-align: end
                    padding: 8px

                & *:not(:last-child)
                    border-bottom: 1px solid $disabled-grey

                svg
                    margin-bottom: 10px

            .dropdown-opener
                cursor: pointer
                display: flex
                svg
                    margin-left: 10px
