@import "../../_common/assets/variables"

.application-form
    max-width: 75vw
    width: 100%
    padding: 30px
    background-color: $secondary-background
    position: relative
    border-top: 1px solid $main-green
    max-height: 75vh
    overflow-y: auto
    .form-heading
        h2
            color: $main-green

    p
        color: $light-grey

    input,
    select
        background-color: transparent
        border-top: 0
        border-left: 0
        border-right: 0
        border-bottom: 1px solid $disabled-grey
        border-radius: 0
        color: $light-grey
        &:hover
            border-bottom: 1px solid $main-green

        &:focus
            box-shadow: none
            border-bottom: 1px solid $main-green

        &::placeholder
            color: $disabled-grey

        &:disabled
            background-color: transparent
            color: $disabled-grey

        option
            background-color: $secondary-background
            color: $light-grey
            border-bottom: 0

    input::file-selector-button
        display: none

    .required::after
        content: " *"

    .not-valid
        border: 1px solid $error-color
        &:hover
            border-bottom: 1px solid $error-color

    .error-message
        color: $error-color

    label
        color: $light-grey

    .input-group-text
        background-color: transparent
        border: none
        border-bottom: 1px solid $disabled-grey
        border-radius: 0
        color: $light-grey

    .application-details
        position: relative
        &::after
            position: absolute
            content: ""
            width: 1px
            height: 100%
            background-color: $disabled-grey
            right: 0
            top: 0
            transform: translateX(10%)

    .btn
        color: $main-green
        display: flex
        align-items: center
        padding-left: 0
        &:hover
            color: $button-hover

        span
            margin-right: 5px
            margin-bottom: -5px

    .btn-submit
        float: right

    .remove-btn
        color: $error-color

    small
        display: block

    .upload-image
        color: $main-green
        &:hover
            color: $button-hover

    .change-image
        color: $main-green
        display: block
        &:hover
            color: $button-hover
