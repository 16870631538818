@import "../../_common/assets/variables"

.customize-sidebar
    .error-message
        color: $error-color
    .not-valid 
        border: 1px solid $error-color
        &:hover
            border-bottom: 1px solid $error-color
    .mandatoryFields
        .form-group
            display: flex
            flex-direction: row-reverse
            align-items: center
            justify-content: flex-end

    .section-title
        margin-bottom: 0
    
    .tooltip-icon
        width: 15px
        height: 15px
        font-size: 15px
        margin-bottom: 1px
        margin-left: 5px
        color: $light-grey

    .section-subtitle
        color: $light-grey
        font-family: "Karla"

    .form-group
        .form-label
            color: $light-grey
            margin-bottom: 0
            font-family: "Karla"
            display: flex
            align-items: center

        input.form-control,
        select.form-control
            background-color: transparent
            border-top: 0
            border-left: 0
            border-right: 0
            border-bottom: 1px solid $disabled-grey
            border-radius: 0
            color: $light-grey
            margin-bottom: 15px
            font-family: "Karla"
            &:hover
                border-bottom: 1px solid $main-green

            &:focus
                box-shadow: none
                border-bottom: 1px solid $main-green

            &::placeholder
                color: $disabled-grey
            
            option, optgroup
                background-color: $secondary-background
                color: $light-grey
                border-bottom: 0
            
            optgroup
                color: $main-green

    .actions
        display: flex
        justify-content: space-between
        padding: 10px
        position: fixed
        bottom: 0
        right: 0
        width: 30vw
        background-color: $sidebar-background
        .cancel-btn
            color: $main-green
            &:hover
                color: $button-hover

.connection-sidebar
    h6
        font-weight: 800