@import "src/_common/assets/variables"

.component-form
    max-width: 85vw
    width: 100%
    height: 85%
    padding: 30px
    background-color: $secondary-background
    position: relative
    border-top: 1px solid $main-green

    .form-heading
        h2
            font-size: 3vh
            color: $main-green

    p
        color: $light-grey

    .MuiTabs-root
        .MuiTabs-flexContainer
            justify-content: space-around
            .MuiButtonBase-root
                width: 25%
                font-size: 2vh
                text-transform: none
                font-family: 'Aldrich'
                color: $text-color
                max-width: 33%
                padding: 3vh 1.5vh
                border-bottom: 1px solid $disabled-grey
                &.Mui-selected
                    color: $main-green

        .MuiTabs-indicator
            background-color: $main-green

    .MuiTabPanel-root
        height: 45vh
        overflow-y: auto
        &.applications
            padding-bottom: 8vh

    .row
        h5
            color: $main-green

        ul
            list-style: none

        small
            display: flex
            justify-content: flex-end

    input, textarea
        background-color: transparent
        border-top: 0
        border-left: 0
        border-right: 0
        border-bottom: 1px solid $disabled-grey
        border-radius: 0
        color: $light-grey
        &:hover
            border-bottom: 1px solid $main-green
        &:focus
            color: $light-grey
            background-color: transparent
            box-shadow: none
            border-bottom: 1px solid $main-green
        &::placeholder
            color: $disabled-grey

    .not-valid
        border: 1px solid $error-color
        &:hover
            border-bottom: 1px solid $error-color

    .error-message
        color: $error-color

    label
        color: $light-grey

    .component-details
        position: relative
        &::after
            position: absolute
            content: ""
            width: 1px
            height: 100%
            background-color: $disabled-grey
            right: 0
            top: 0
            transform: translateX(10%)
    .tags
        fieldset
            border: none

        &.MuiAutocomplete-hasClearIcon .MuiOutlinedInput-root,
        .MuiOutlinedInput-root
            padding: 0

        .MuiAutocomplete-tag,
        .MuiChip-deleteIcon,
        .MuiAutocomplete-clearIndicator
            color: $light-grey

    .actions
        display: flex
        justify-content: space-between
        position: absolute
        bottom: 0
        width: 100%
        left: 0
        padding: 30px
        .cancel-btn
            color: $main-green
            &:hover
                color: $button-hover

.section-title
    color: $main-green

.input-with-unit
    display: flex
    align-items: baseline

.unit
    margin-left: 5px
    vertical-align: middle

input[type="number"]
    -moz-appearance: textfield
    -webkit-appearance: textfield
      appearance: textfield
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button
        -webkit-appearance: none
        margin: 0