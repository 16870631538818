@import "src/_common/assets/variables"

.account-form
    max-width: 65vw
    max-height: 80vh
    padding: 30px
    background-color: $secondary-background
    position: relative
    border-top: 1px solid $main-green
    .form-heading
        h2
            color: $main-green

    p
        color: $light-grey

    .not-valid.form-control
        border: 1px solid $error-color
        &:hover
            border-bottom: 1px solid $error-color

    input.form-control
        background-color: transparent
        border-top: 0
        border-left: 0
        border-right: 0
        border-bottom: 1px solid $disabled-grey
        border-radius: 0
        color: $light-grey
        &:hover
            border-bottom: 1px solid $main-green
        &:focus
            box-shadow: none
            border-bottom: 1px solid $main-green
        &::placeholder
            color: $disabled-grey

    label
        color: $light-grey

    .disabled-grey
        color: $disabled-grey

    .account-details
        position: relative
        &::before
            position: absolute
            content: ""
            width: 1px
            height: 100%
            background-color: $disabled-grey
            right: -10%
            transform: translateX(10%)

    .import-heading
        display: flex
        justify-content: space-between
        align-items: center
        p
            margin-bottom: -5px
        .btn.button
            padding: 0.5vh 1vh

    .packages-instruction
        font-size: 1vh

    .packages-container
        width: 100%
        max-height: 35vh
        overflow-y: auto
        margin: 1vh 0
        padding-right: 1vh

        input:disabled
            color: $disabled-grey

        .initial-packages
            .input-container
                position: relative
                svg
                    position: absolute
                    bottom: 10px
                    right: 0
                    width: 2vh
                    font-size: 2vh
                    padding: 0
                & .imported
                    path
                        color: $main-green
                & .error
                    path
                        color: $error-color
                & .pending
                    path
                       color: $pending-package
                & .missing_used_component_theme
                    path
                        color: $warning-color
                & .not_owned_by_user
                    path
                        color: $error-color
                & .update_error
                    path
                        color: $warning-color

        .editable-packages
            position: relative

        .remove-btn.btn.button
            padding: 0 5px
            font-weight: 800
            background-color: $error-color
            position: absolute
            bottom: 5px
            right: 0
            line-height: 1
            &:hover
                background-color: $error-color

    .actions
        text-align: right
        .btn.button
            width: 100%
            margin-top: 2vh
