@import "../assets/variables"

.ibl-modal
    .modal-body
        background-color: $card-background
        width: 40vw
        height: auto
        border-top: 2px solid $main-green
        display: flex
        flex-direction: column
        justify-content: space-between
        padding: 15px
        .modal-message
            height: 100%
            display: flex
            justify-content: center
            align-items: center
            flex-direction: column
            .modal-title
                font-size: 2.5vh
                color: $main-green
                text-align: center
                margin: 25px

            .modal-description
                text-align: justify
                color: $light-grey

            .modal-label
                font-size: 14px
                color: $text-color

        .actions
            display: flex
            justify-content: space-between
            .yes-btn
                color: $main-green
                background-color: transparent

            .no-btn
                background-color: $main-green

            &.reversed
                flex-direction: row-reverse
                .no-btn
                    color: $main-green
                    background-color: transparent

                .yes-btn
                    background-color: $main-green
                    color: $button-text