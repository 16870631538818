@import "src/_common/assets/variables"

.app-container
    color: $text-color
    .connect-container
        height: 100vh
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
        text-align: center
        width: 38rem
        margin: 0 auto
        h1
            font-size: 75px
            margin-bottom: 80px
            margin-top: auto
        p
            font-size: 35px
        img
            margin-bottom: 65px
        .funds-logos-container
            margin-top: auto
            img
                width: 100%
                margin-bottom: 15px
            p
                font-size: 12px

.home-container
    width: 85vw
    min-height: 85vh
    position: relative
    padding: 2em
    color: $light-grey
    font-size: 1rem
    .MuiTabs-root
        .MuiTabs-flexContainer
            justify-content: space-around
            .MuiButtonBase-root
                width: 100%
                font-size: 1rem
                text-transform: none
                font-family: 'Aldrich'
                color: $text-color
                max-width: 50%
                padding: 3vh 1.5vh
                border-bottom: 1px solid $disabled-grey
                &.Mui-selected
                    color: $main-green

        .MuiTabs-indicator
            background-color: $main-green

    input, textarea
        background-color: transparent
        border-top: 0
        border-left: 0
        border-right: 0
        border-bottom: 1px solid $disabled-grey
        border-radius: 0
        color: $light-grey
        &:hover
            border-bottom: 1px solid $main-green
        &:focus
            color: $light-grey
            background-color: transparent
            box-shadow: none
            border-bottom: 1px solid $main-green
        &::placeholder
            color: $disabled-grey

    h2
        font-size: 2.5rem
        color: $main-green

    .section
        height: 100%
        background-color: $secondary-background
        .section-title
            color: $main-green
            margin-bottom: 0.8em

    .row, .col
        margin: 0
        padding: 0
        gap: 1.5em

    .no-gap
        gap: 0

    .padded
        padding: 1em

    .heading
        margin-bottom: 1em

    .content-left, .content-right
        margin: 0
        padding: 0
        height: auto
        align-content: space-between

    .owned-items
        font-size: 1rem
        display: flex
        flex-direction: column
        justify-content: space-between
        height: 100%
        .item-list
            height: auto
            max-height: 45vh
            overflow-y: auto
            .item
                padding: 0.4em
                color: $text-color
                border-bottom: 1px solid $menu-background
                &:last-child
                    border-bottom: none
                .item-property
                    font-size: 0.8rem
                    display: flex
                    justify-content: space-between
                    align-items: center
                    & *
                        color: $light-grey

        .claims
            padding-top: 1em
            border-top: 2px solid $menu-background

    .content-right__sections
        display: flex
        flex-direction: column
        height: 100%
        justify-content: space-between
        gap: 1.5em
        .staking
            font-size: 0.8rem
            .tab-panel
                height: auto
            .tab
                display: flex
                flex-direction: column
                justify-content: space-between
                gap: 1.5em
                .label
                    color: $text-color
                .value
                    color: $light-grey

    .button
        min-width: 9rem
        .button-text
            border: 1px solid red
        .button-spinner
            height: 1.2rem
            width: 1.2rem
    .cancel-btn
        color: $main-green
        &:hover
            color: $button-hover

    .claim-container
        overflow-y: auto
        font-size: 1rem
        .claim
            height: 100%
            display: flex
            justify-content: space-between
            gap: 1em
            .claim__text
                display: flex
                flex-direction: column
                justify-content: space-between
            .claim__icon
                color: $text-color
                font-size: 2rem

.modal-content
    position: relative
    height: 100%
    width: 100%
    .account-container
        position: absolute
        top: 50%
        left: 50%
        transform: translate(-50%, -50%)
        width: 65vw

    .account-form
        .actions
            .btn.button
                width: auto
                margin-top: 0

    .skip-btn.btn
        color: $main-green
        float: left
        &:hover
            color: $button-hover
