@import "../../_common/assets/variables"
.dashboard-container
    width: 85vw
    height: 85%
    background-color: $secondary-background
    position: relative
    .MuiTabs-root
        .MuiTabs-flexContainer
            justify-content: space-around
            .MuiButtonBase-root
                width: 100%
                font-size: 3vh
                text-transform: none
                font-family: 'Aldrich'
                color: $text-color
                max-width: 50%
                padding: 3vh 1.5vh
                border-bottom: 1px solid $disabled-grey
                &.Mui-selected
                    color: $main-green

        .MuiTabs-indicator
            background-color: $main-green

    .MuiTabPanel-root
        height: 86%
        &.applications
            padding-bottom: 8vh

.gallery
    height: 100%
    overflow-y: auto
    overflow-x: hidden
    padding: 0 10px
    .card-container
        padding-bottom: 15px
        .card
            padding: 2.5vh
            background-color: $button-disabled
            border: 0
            border-radius: 0
            height: 100%
            &.disabled
                background-color: $component-disabled
            .card-heading
                display: flex
                justify-content: space-between
                align-items: center
                margin-bottom: 30px
                h4.card-title
                    color: $main-green
                    margin-bottom: -6px

                svg
                    width: 20px
                    height: 20px

            .card-body
                display: flex
                justify-content: space-between
                align-items: center
                p.card-text
                    color: $light-grey
                    white-space: nowrap
                    font-size: 1.5vh
                    margin-bottom: -5px

                svg
                    width: 20px

                .status
                    span
                        text-transform: uppercase
                        background-color: $component-invalid
                        color: $secondary-background
                        padding: 8px 5px 5px 5px
                        font-size: 1vh
                        white-space: nowrap

        .dropdown-container
            position: relative
            .dropdown-opener
                cursor: pointer
                display: flex

            .dropdown-list
                background-color: $dropdown-background
                position: absolute
                padding: 30px
                right: -10px
                font-size: 20px
                z-index: 2
                & *
                    justify-content: flex-end
                & *:not(:last-child)
                    border-bottom: 1px solid $disabled-grey
                a
                    text-decoration: none
                    color: $main-green
                    display: flex
                    cursor: pointer
                    svg
                        padding-left: 10px
                .btn-action
                    padding: 6px 0
                    display: flex
                    font-size: 20px
                    align-items: baseline
                    svg
                        margin-left: 5px
                    &.btn-disable
                        color: $error-color
                    &.btn-enable
                        color: $main-green
                    &.btn-publish
                        color: $main-green

    .no-app
        height: 100%
        display: flex
        justify-content: center
        align-items: center

.btn-container
    position: absolute
    bottom: 0
    left: 0
    width: 100%
    a
        max-height: 4vh
        height: 100%
        .new-app-btn
            background-color: $main-green
            color: $button-text
            border-radius: 0
            width: 100%
            max-height: 5vh
            height: 100%
            display: flex
            justify-content: center
            align-items: center
            &:hover
                background-color: $button-hover
                color: $button-text

            svg
                height: 3vh
                width: 3vh

            span
                margin-bottom: -5px
                padding-left: 5px

    .btn.button.show-btn
        color: $main-green
        background-color: $secondary-background
        width: 100%
        max-height: 5vh
        height: 100%
        display: flex
        justify-content: center
        align-items: center
        &:hover
            background-color: $button-text
            color: $main-green