@import "../../_common/assets/variables"

.ide
    display: flex
    width: 100vw
    height: 100%
    overflow: hidden

.component-menu
    height: auto
    display: flex
    flex-direction: column
    background-color: $side-menu-background
    &.scroll
        overflow-y: auto
    input.form-control
        background-color: transparent
        width: auto
        margin: 15px 0 0 0
        border-top: 0
        border-left: 0
        border-right: 0
        border-bottom: 1px solid $light-grey
        border-radius: 0
        color: $light-grey
        &:hover
            border-bottom: 1px solid $main-green

        &:focus
            box-shadow: none
            border-bottom: 1px solid $main-green

        &::placeholder
            color: $light-grey

    .available-components
        height: 100%

    .description
        color: $main-green
        font-size: 1vh

.component
    font-size: 0.9vw
    padding: 15px
    border: 1px solid $side-menu-background
    border-radius: 0
    align-items: center
    justify-content: center
    background-color: $disabled-grey
    color: $main-green
    cursor: grab
    height: auto!important
    text-overflow: ellipsis
    overflow: hidden
    white-space: nowrap

.drawing-board
    height: auto
    display: flex
    flex-direction: column
    .drawing-board-container
        position: relative
        height: 95%
        margin: 0 30px 30px 30px
        padding-bottom: 60px
        background-color: $disabled-grey
        .board
            .drop-zone
                height: 100%
                width: 100%
                align-items: center
                justify-content: center
                border: 2px dashed $side-menu-background
                .dropped-component
                    transform: translate(-2px, -2px)
                    height: 70px
                    line-height: 70px
                    width: auto
                    text-align: center
                    border: 2px solid $side-menu-background
                    align-items: center
                    justify-content: center
                    background-color: $disabled-grey
                    opacity: 0.9
                    color: $main-green
                    cursor: grab
                    .hotspot
                        height: 12px
                        width: 12px
                        background-color: $main-green
                        border: $main-green
                        border-radius: 100%
                        position: absolute
                        transform: translate(-50%, -50%)
                        &:disabled
                            background-color: $side-menu-background

                    .hotspot-top
                        left: 50%
                        top: 0

                    .hotspot-right
                        left: 100%
                        top: 50%

                    .hotspot-bottom
                        left: 50%

                    .hotspot-left
                        left: 0
                        top: 50%

                    .selected
                        background-color: $button-hover
                        border: 4px solid $button-hover

                .incomplete
                    border: 2px solid $warning-color

                .error
                    border: 2px solid $error-color

        .control-buttons
            position: absolute
            bottom: 0
            display: flex
            margin-bottom: 15px
            margin-left: 10px
            .btn.button
                background-color: $side-menu-background
                color: $main-green
                border-radius: 0
                justify-content: center
                align-items: center
                padding: 5px
                margin: 5px
                svg
                    display: flex
    .drawing-board-menu
        margin: 30px 30px 5px 30px
        color: $main-green
        align-items: center
        display: flex
        justify-content: space-between

        .menu--left
            padding: 0
            display: flex
            align-items: center
            .btn
                color: $main-green
                display: flex
                align-items: center
                padding-left: 0
                span
                    margin-left: 5px
                    margin-bottom: -2px
            .app-details
                display: flex
                align-items: center
                p
                    margin: 0
                    text-transform: uppercase
                    color: $light-grey

                span
                    color: $text-color
                    padding: 0 3px
                    margin-bottom: -2px

                small
                    text-transform: lowercase
                    color: $text-color

        .menu--right
            display: flex
            justify-content: flex-end
            padding: 0
            align-items: center
            h6
                white-space: nowrap
                overflow: hidden
                text-overflow: ellipsis
                padding-top: 6px
            small
                padding-top: 6px
            .btn
                margin-left: 10px

            .action-btn
                display: flex
                align-items: center
                padding-left: 0
                max-height: 30px
                span
                    margin-bottom: -3px
                svg
                    margin: 0 2px

            .app-fee
                display: flex
                align-items: center
                margin-right: 30px
                .user-fee
                    display: flex
                    align-items: center
                    .unit
                        font-size: 12px
                        margin-left: 5px
                        margin-right: 5px
                    label
                        font-size: 14px
                        margin-right: 3px
                .fee-input
                    .input-with-button
                        display: flex
                        align-items: center
                        position: relative
                        .form-control
                            font-size: 14px
                            height: 26px
                            padding-right: 30px
                            width: 120px
                        &::after
                            content: "ETH"
                            position: absolute
                            top: 50%
                            margin-left: 20px
                            right: 69px
                            transform: translateY(-50%)
                            font-size: 12px
                            color: $button-text
                        .change-fee-btn
                            font-size: 10px
                            background-color: $main-green
                            color: $button-text
                            border: none
                            height: 26px
                            cursor: pointer
                            margin-left: 5px
                            &:hover
                                background-color: $button-hover
                            &:disabled
                                background-color: $text-color

            .download-btn--container,
            .publish-btn--container
                position: relative
                display: flex
                .download-btn,
                .publish-btn
                    color: $main-green
                    white-space: nowrap
                    padding: 0 5px
                    border: 1px solid $text-color
                    &:hover
                        border-color: $main-green
                        border-bottom-left-radius: 0
                        border-bottom-right-radius: 0

                    &:disabled
                        border: none

                .publish-btn
                    svg
                        width: 14px
                        height: 14px
                        fill: $main-green

                .estimated-cost
                    font-size: 12px
                    color: $text-color

                .cost-container
                    position: absolute
                    z-index: 9
                    top: 21px
                    right: 0
                    width: calc(100% - 10px)
                    border: 1px solid $main-green
                    border-top: 0
                    border-bottom-left-radius: 10px
                    border-bottom-right-radius: 10px
                    background-color: $main-background
                    padding-top: 3px
                    .item
                        width: 100%
                        display: flex
                        justify-content: space-between
                        align-items: center
                        padding: 2px 5px
                        border-bottom: 1px solid rgba(150, 150, 150, 0.2)
                        & *
                            font-size: 10px
                            color: $light-grey

                        &:last-child
                            border-bottom: 0

            .discard-btn
                color: $warning-color

            .save-app-btn
                background-color: $main-green
                color: $button-text
                border-radius: 0
                display: flex
                padding: 10px 40px
                &:hover
                    background-color: $button-hover
                    color: $button-text

                span
                    margin-bottom: -2px

#svg
    position: absolute
    width: 100%
    height: 100%

.customize-component-button
    background-color: $disabled-grey
    color: $side-menu-background
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    width: 100%
    max-height: 155px
    height: 90%
    &:disabled
        color: $error-color
        border: $side-menu-background

.component-name
    white-space: nowrap
    overflow: hidden
    text-overflow: clip
    text-align: left

.btn.delete-component-btn
    color: $error-color
    position: absolute
    top: -5px
    right: -10px
    &:hover
        color: $error-color

.connection-line
    stroke: $main-green
    stroke-width: 4px
    cursor: pointer
    &:hover
        stroke: $button-hover
        stroke-width: 5px

.connection-marker
    fill: $secondary-background

.incomplete
    stroke: $warning-color
    fill: $warning-color

.error
    stroke: $error-color
    fill: $error-color

.component-sidebar,
.connection-sidebar
    position: absolute
    .MuiPaper-root
        background-color: $sidebar-background
        height: 91vh
        width: 30vw
        bottom: 0
        top: inherit
        border-top: 5px solid $main-green
        padding: 5vh 2.5vh
        padding-bottom: 70px

    .delete-btn
        color: $error-color
        position: absolute
        top: 5px
        right: 5px
        &:hover
            color: $error-color

.ibl-modal.cost-modal
    .modal-body
        background-color: $secondary-background
        .modal-message
            align-items: flex-start
            .modal-title
                margin: 25px 0
            .modal-fees
                width: 100%

            .cost-container,
            .total-cost
                width: 100%
                .item
                    display: flex
                    justify-content: space-between
                    padding: 2px 0
                    & *
                        font-size: 14px
                        color: $text-color
                        margin-bottom: -3px

            .cost-container
                .item
                    border-bottom: 1px solid rgba(150, 150, 150, 0.2)

                    &:last-child
                        border-bottom: 0

                    &.item-last
                        border-bottom: 1px solid rgba(150, 150, 150, 0.2)

            .total-cost
                .item
                    & *
                        color: $warning-color
                        font-weight: 800
                        padding: 20px 0 35px 0

    input, textarea
        background-color: transparent
        border-top: 0
        border-left: 0
        border-right: 0
        border-bottom: 1px solid $disabled-grey
        border-radius: 0
        color: $light-grey
        &:hover
            border-bottom: 1px solid $main-green
        &:focus
            color: $light-grey
            background-color: transparent
            box-shadow: none
            border-bottom: 1px solid $main-green
        &::placeholder
            color: $disabled-grey
