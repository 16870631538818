@import "../../_common/assets/variables"

.wizard-container
    height: 100%
    width: 100%
    background-color: $main-green
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    img
        margin-bottom: 25px
        max-width: 125px
        width: 100%
    h2, .description, a
        color: #fff
